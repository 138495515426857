import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { carlosgub.dev.pages.HomePage() }
        ctx.router.register("/presentation/create-kobweb") {
                carlosgub.dev.pages.presentation.CreateKobWebPage() }
        ctx.router.register("/presentation/firestore-kmm") {
                carlosgub.dev.pages.presentation.FirestoreKMMPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("about-me-section",
                carlosgub.dev.components.styles.AboutMeSectionStyle)
        ctx.theme.registerStyle("back-to-top-button",
                carlosgub.dev.components.styles.BackToTopButtonStyle)
        ctx.theme.registerStyle("contact-me-section",
                carlosgub.dev.components.styles.ContactMeSectionStyle)
        ctx.theme.registerStyle("contact-me-button",
                carlosgub.dev.components.styles.ContactMeButton)
        ctx.theme.registerStyle("message-contact-me",
                carlosgub.dev.components.styles.MessageContactMeStyle)
        ctx.theme.registerStyle("experience-section",
                carlosgub.dev.components.styles.ExperienceSectionStyle)
        ctx.theme.registerStyle("company-link", carlosgub.dev.components.styles.CompanyLink)
        ctx.theme.registerStyle("p-experience", carlosgub.dev.components.styles.PExperienceStyle)
        ctx.theme.registerStyle("footer", carlosgub.dev.components.styles.FooterStyle)
        ctx.theme.registerStyle("link-footer", carlosgub.dev.components.styles.LinkFooterStyle)
        ctx.theme.registerStyle("developed-by-link",
                carlosgub.dev.components.styles.DevelopedByLink)
        ctx.theme.registerStyle("go-to-contact-me-button",
                carlosgub.dev.components.styles.GoToContactMeButton)
        ctx.theme.registerStyle("intro-container",
                carlosgub.dev.components.styles.IntroContainerStyle)
        ctx.theme.registerStyle("intro-desktop", carlosgub.dev.components.styles.IntroDesktopStyle)
        ctx.theme.registerStyle("intro-mobile", carlosgub.dev.components.styles.IntroMobileStyle)
        ctx.theme.registerStyle("tag-line", carlosgub.dev.components.styles.TagLineStyle)
        ctx.theme.registerStyle("profile-photo", carlosgub.dev.components.styles.ProfilePhotoStyle)
        ctx.theme.registerStyle("projects-section",
                carlosgub.dev.components.styles.ProjectsSectionStyle)
        ctx.theme.registerStyle("project-content",
                carlosgub.dev.components.styles.ProjectContentStyle)
        ctx.theme.registerStyle("project-content-text",
                carlosgub.dev.components.styles.ProjectContentTextStyle)
        ctx.theme.registerStyle("talk-section", carlosgub.dev.components.styles.TalkSectionStyle)
        ctx.theme.registerStyle("toolbar-container",
                carlosgub.dev.components.styles.ToolbarContainerStyle)
        ctx.theme.registerStyle("toolbar", carlosgub.dev.components.styles.ToolbarStyle)
        ctx.theme.registerStyle("toolbar-item", carlosgub.dev.components.styles.ToolbarItemStyle)
        ctx.theme.registerStyle("toolbar-menu-mobile",
                carlosgub.dev.components.styles.ToolbarMenuMobileStyle)
        ctx.theme.registerStyle("moving-text",
                carlosgub.dev.components.styles.components.MovingText)
        ctx.theme.registerStyle("hr", carlosgub.dev.components.styles.components.HRStyle)
        ctx.theme.registerStyle("h1", carlosgub.dev.components.styles.components.H1Style)
        ctx.theme.registerStyle("h2", carlosgub.dev.components.styles.components.H2Style)
        ctx.theme.registerStyle("h3", carlosgub.dev.components.styles.components.H3Style)
        ctx.theme.registerStyle("h4", carlosgub.dev.components.styles.components.H4Style)
        ctx.theme.registerStyle("h6", carlosgub.dev.components.styles.components.H6Style)
        ctx.theme.registerStyle("link", carlosgub.dev.components.styles.components.LinkStyle)
        ctx.theme.registerStyle("link-talk",
                carlosgub.dev.components.styles.components.LinkTalkStyle)
        ctx.theme.registerStyle("p", carlosgub.dev.components.styles.components.PStyle)
        ctx.theme.registerStyle("ul", carlosgub.dev.components.styles.components.UlStyle)
        ctx.theme.registerVariant("-primary", carlosgub.dev.components.widgets.PrimaryButtonVariant
        )
        ctx.theme.registerVariant("-normal", carlosgub.dev.components.widgets.NormalButtonVariant
        )
        ctx.theme.registerKeyframes("fade-in-key-frames",
                carlosgub.dev.components.keyframe.FadeInKeyFrames)
        ctx.theme.registerKeyframes("shake", carlosgub.dev.components.keyframe.Shake)
        ctx.theme.registerKeyframes("width-key-frames",
                carlosgub.dev.components.keyframe.WidthKeyFrames)
        carlosgub.dev.enableSiteWideSmoothScrolling(ctx)
        carlosgub.dev.components.toolbar.initNavHeaderStyles(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Carlos Ugaz"))
    renderComposable(rootElementId = "root") {
        carlosgub.dev.MyApp {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
